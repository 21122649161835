<template>
  <el-table
    :data="data"
    stripe
    style="width: 100%"
    :row-class-name="rowClassName"
  >
    <el-table-column prop="status" label="" width="50" align="center">
      <template slot-scope="scope">
        <font-awesome-icon
          :icon="
            tracker && tracker[scope.row.key] ? 'check-circle' : 'times-circle'
          "
          fixed-width
        ></font-awesome-icon>
      </template>
    </el-table-column>

    <el-table-column prop="name" label="Service"> </el-table-column>

    <el-table-column label="" width="100" align="right">
      <template slot-scope="scope">
        <template v-if="tracker && !tracker[scope.row.key]">
          <a @click="openEditModal(scope.row.key)" class="has-cursor-pointer"
            >Setup</a
          >
        </template>
        <template v-else>
          <el-dropdown
            trigger="click"
            @command="trackerCompanyDropdown($event, scope.row)"
          >
            <span class="el-dropdown-link">
              <el-button type="text" style="padding: 0" size="small">
                <font-awesome-icon
                  icon="ellipsis-v"
                  fixed-width
                ></font-awesome-icon>
              </el-button>
            </span>
            <el-dropdown-menu class="dropdown-left-adjust" slot="dropdown">
              <el-dropdown-item command="edit">
                <font-awesome-icon
                  icon="pencil-alt"
                  fixed-width
                ></font-awesome-icon>
                Edit
              </el-dropdown-item>
              <el-dropdown-item command="delete">
                <font-awesome-icon
                  icon="trash-alt"
                  fixed-width
                ></font-awesome-icon>
                Delete
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import TrackerMailChimpForm from "@/components/Forms/TrackerMailChimpForm"
import TrackerSiteimpactForm from "@/components/Forms/TrackerSiteimpactForm"
import TrackerSupermetricsForm from "@/components/Forms/TrackerSupermetricsForm"
import TrackerSimplifiForm from "@/components/Forms/TrackerSimplifiForm"
import TrackerAgilityAdsForm from "@/components/Forms/TrackerAgilityAdsForm"
import TrackerTremorForm from "@/components/Forms/TrackerTremorForm"
import TrackerEmailResultzForm from "@/components/Forms/TrackerEmailResultzForm"
import TrackerConstantContactForm from "@/components/Forms/TrackerConstantContactForm"
import TrackerBoostForm from "@/components/Forms/TrackerBoostForm.vue";

export default {
  name: "TrackerIntegrationsTable",

  mixins: [],
  components: {},

  props: {},
  data() {
    return {
      data: [
        {
          name: "Supermetrics",
          key: "supermetrics",
          form: TrackerSupermetricsForm,
          modal: "tracker-supermetrics-form",
          routeKey: "supermetrics",
        },
        {
          name: "Simplifi",
          key: "simplifi_id",
          form: TrackerSimplifiForm,
          modal: "tracker-simplifi-form",
          routeKey: "simplifi",
        },
        {
          name: "AgilityAds",
          key: "agilityads_id",
          form: TrackerAgilityAdsForm,
          modal: "tracker-agility-form",
          routeKey: "agility",
        },
        {
          name: "Siteimpact",
          key: "siteimpact_slugs",
          form: TrackerSiteimpactForm,
          modal: "tracker-siteimpact-form",
          routeKey: "siteimpact",
        },
        {
          name: "MailChimp",
          key: "mailchimp_settings",
          form: TrackerMailChimpForm,
          modal: "tracker-mailchimp-form",
          routeKey: "mailchimp",
        },
        {
          name: "Tremor Video",
          key: "tremor_id",
          form: TrackerTremorForm,
          modal: "tracker-tremor-form",
          routeKey: "tremor",
        },
        {
          name: "Email Resultz",
          key: "email_resultz_match",
          form: TrackerEmailResultzForm,
          modal: "tracker-email-resultz-form",
          routeKey: "emailresultz",
        },
        {
          name: "Boost",
          key: "boost_match",
          form: TrackerBoostForm,
          modal: "tracker-boost-form",
          routeKey: "boost",
        },
        {
          name: "Constant Contact",
          key: "constant_contact_key",
          form: TrackerConstantContactForm,
          modal: "tracker-constant-contact-form",
          routeKey: "constantcontact",
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      tracker: "tracker/currentAdmin",
      company: "companies/current",
    }),
  },
  watch: {},

  methods: {
    ...mapActions({
      updateCompany: "tracker/updateAdmin",
    }),
    rowClassName(row) {
      if (!this.tracker) return ""
      if (this.tracker[row.row.key] === null) return "inactive"
      return ""
    },
    openEditModal(key) {
      let match = this.$_.find(this.data, ["key", key])
      if (match) {
        this.$modal.show(
          match.form,
          {
            existing: this.tracker[key],
          },
          {
            name: match.modal,
            adaptive: true,
            height: "auto",
            scrollable: true,
            width: match.modal == "tracker-supermetrics-form" ? 1000 : 600,
          }
        )
      }
    },
    trackerCompanyDropdown(command, tracker) {
      if (command === "edit") {
        this.openEditModal(tracker.key)
      } else if (command === "delete") {
        let message =
          "Delete " +
          tracker.name +
          " integration? This will remove all associated data."
        this.$confirm(message, "", {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "error",
          callback: (action) => {
            if (action === "confirm") {
              let route =
                "tracker/" +
                this.company.slug +
                "/" +
                tracker.routeKey +
                "/delete"
              this.axios
                .post(route, {})
                .then((response) => {
                  this.updateCompany(response.data.data)
                  this.$message.success(response.data.message)
                })
                .catch((resp) => {
                  this.$root.globalRequestError(resp)
                })
            }
          },
        }).catch(() => {})
      }
    },
  },

  created() {},
  mounted() {},
}
</script>

<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Boost Integration Settings</span>
      <el-button type="text" @click="$modal.hide('tracker-boost-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="submit"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item
        :error="
          form.errors.first('string') ? form.errors.first('string') : null
        "
        label="Match"
        required
      >
        <el-input
          v-model="data.string"
          type="text"
          @focus="form.errors.clear('string')"
        ></el-input>
        <p class="help-text">
          Any Campaign Name that matches this string (case insensitive) will be
          assigned to this tracker.
        </p>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
        :disabled="loading"
      >
        Submit
      </el-button>
    </el-form>
  </el-card>
</template>

<style scoped>
</style>

<script>
import Form from "form-backend-validation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "TrackerBoostForm",

  mixins: [],
  components: {},

  props: {
    existing: {
      required: true,
    },
  },

  data() {
    return {
      data: {
        string: "",
      },
      form: new Form(),
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      company: "companies/current",
    }),
    action() {
      console.log(this.company)
      return "tracker/" + this.company.slug + "/boost/update"
    },
  },
  watch: {},

  methods: {
    ...mapActions({
      updateCompany: "tracker/updateAdmin",
    }),
    submit() {
      this.loading = true

      let formData = {
        string: this.data.string,
      }

      this.axios
        .post(this.action, formData)
        .then((response) => {
          this.updateCompany(response.data.data)
          this.$message.success(response.data.message)
          this.$modal.hide("tracker-boost-form")
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },

  created() {
    if (this.existing !== null) {
      this.data.string = this.existing
    }

    console.log(this.data)
  },
  mounted() {},
}
</script>
